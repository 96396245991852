<ng-container *transloco="let t; read: 'actionable'">
  @if (actions.length > 0) {
    <div ngbDropdown container="body" class="d-inline-block">
      <button [disabled]="disabled" class="btn {{btnClass}}" id="actions-{{labelBy}}" ngbDropdownToggle
              (click)="preventEvent($event)"><i class="fa {{iconClass}}" aria-hidden="true"></i></button>
      <div ngbDropdownMenu attr.aria-labelledby="actions-{{labelBy}}">
        <ng-container *ngTemplateOutlet="submenu; context: { list: actions }"></ng-container>
      </div>
    </div>
    <ng-template #submenu let-list="list">
      @for(action of list; track action.id) {
        <!-- Non Submenu items -->
        @if (action.children === undefined || action?.children?.length === 0 || action.dynamicList !== undefined) {
          @if (action.dynamicList !== undefined && (action.dynamicList | async | dynamicList); as dList) {
            @for(dynamicItem of dList; track dynamicItem.title) {
              <button ngbDropdownItem (click)="performDynamicClick($event, action, dynamicItem)">{{dynamicItem.title}}</button>
            }
          } @else if (willRenderAction(action)) {
            <button ngbDropdownItem (click)="performAction($event, action)" (mouseover)="closeAllSubmenus()">{{t(action.title)}}</button>
          }
        } @else {
          @if (shouldRenderSubMenu(action, action.children?.[0].dynamicList | async)) {
            <!-- Submenu items -->
            <div ngbDropdown #subMenuHover="ngbDropdown" placement="right-top"
                 (click)="preventEvent($event); openSubmenu(action.title, subMenuHover)"
                 (mouseover)="preventEvent($event); openSubmenu(action.title, subMenuHover)"
                 (mouseleave)="preventEvent($event)">
              @if (willRenderAction(action)) {
                <button id="actions-{{action.title}}" class="submenu-toggle" ngbDropdownToggle>{{t(action.title)}} <i class="fa-solid fa-angle-right submenu-icon"></i></button>
              }
              <div ngbDropdownMenu attr.aria-labelledby="actions-{{action.title}}">
                <ng-container *ngTemplateOutlet="submenu; context: { list: action.children }"></ng-container>
              </div>
            </div>
          }
        }
      }
    </ng-template>
  }
</ng-container>
